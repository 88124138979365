<template>
  <div style="height: 100vh;display: flex;flex-direction: column;">

    <div style="margin:10px 0;display: flex;justify-content: center;">
      <el-button-group>
        <el-button v-for="(item,index) in btnArr " :key="index" :type="activeIndex==index?'primary':''"
                   @click="onBtnClick(index)">{{ item }}
        </el-button>
      </el-button-group>

      <el-button type="danger" style="margin-left: 150px;"
                 @click="onSaveJson">提交保存
      </el-button>
    </div>

    <div style="flex:1;">
      <!--    源码区-->
      <div v-if="activeIndex==1" style="width: 90%;height: 90%;margin:10px auto;">
        <b-code-editor v-model="editJsonTxt"
                       :auto-format="true"
                       :smart-indent="true"
                       theme="dracula"
                       :indent-unit="4"
                       :line-wrap="false"
                       ref="editor"
                       height="90vh"

        ></b-code-editor>

        <br>

      </div>

      <!--编辑区-->
      <div v-show="activeIndex==0" style="display: flex;">
        <el-menu
            default-active="mainSymptom"
            class="el-menu-vertical-demo"
            @select="onSelectChange"

        >
          <el-menu-item index="mainSymptom">
            <i class="el-icon-menu"></i>
            <span slot="title">主症</span>
          </el-menu-item>
          <el-menu-item index="expand">
            <i class="el-icon-s-data"></i>
            <span slot="title">扩展</span>
          </el-menu-item>
        </el-menu>
        <div style="flex: 1;padding:0 30px;overflow-y: auto;height: 90vh;">
          <!--mainSymptom-->
          <div v-if="selIndex=='mainSymptom'">
            <el-button v-if="jsonObj.mainSymptom.length<1" type="warning"
                       icon="el-icon-circle-plus-outline"
                       @click="onAdd(jsonObj.mainSymptom,-1,mainSymptomItem)"></el-button>
            <el-form ref="form2" label-width="100px">
              <div v-for="(item,index) in jsonObj.mainSymptom" :key="index">
                <div style="margin-top:10px;">
                  <el-badge :value="index+1" class="item">
                    <el-form-item label="症状名称">
                      <div style="display: flex;align-items: center;">
                        <el-input clearable v-model="item.name" style="width:300px;margin-right: 25px;"></el-input>

                        <el-button type="warning" icon="el-icon-circle-plus-outline" size="mini"
                                   @click="onAdd(jsonObj.mainSymptom,index,mainSymptomItem)"></el-button>
                        <el-button type="info" icon="el-icon-upload2" size="mini"
                                   @click="onUp(jsonObj.mainSymptom,index)"></el-button>
                        <el-button type="success" icon="el-icon-download" size="mini"
                                   @click="onDown(jsonObj.mainSymptom,index)"></el-button>

                        <el-button type="danger" icon="el-icon-delete" size="mini"
                                   @click="onDel(jsonObj.mainSymptom,index)"></el-button>
                      </div>

                    </el-form-item>
                  </el-badge>
                  <el-form-item :label="'临床表项['+item.lcbx.length+']'">
                    <el-button v-if="item.lcbx.length<1" type="warning"
                               icon="el-icon-circle-plus-outline"
                               @click="onAdd(item.lcbx,-1,mainSymptomItemLcbxItem)"></el-button>

                    <div style="overflow-y: auto;max-height: 250px;">
                      <el-form ref="form3" label-width="80px" :inline="true" size="mini">
                        <div v-for="(item_lcbx,index_lcbx) in item.lcbx" :key="index_lcbx"
                             style="display: flex;align-items: center;">
                          <el-form-item label="名称">
                            <el-input clearable v-model="item_lcbx.title"></el-input>
                          </el-form-item>
                          <el-form-item label="标志">
                            <el-input clearable v-model="item_lcbx.tag"></el-input>
                          </el-form-item>

                          <el-button type="warning" icon="el-icon-circle-plus-outline" size="mini"
                                     @click="onAdd(item.lcbx,index_lcbx,mainSymptomItemLcbxItem)"></el-button>
                          <el-button type="info" icon="el-icon-upload2" size="mini"
                                     @click="onUp(item.lcbx,index_lcbx)"></el-button>
                          <el-button type="success" icon="el-icon-download" size="mini"
                                     @click="onDown(item.lcbx,index_lcbx)"></el-button>

                          <el-button type="danger" icon="el-icon-delete" size="mini"
                                     @click="onDel(item.lcbx,index_lcbx)"></el-button>

                        </div>

                      </el-form>

                    </div>
                  </el-form-item>
                  <el-form-item label="调理方法">
                    <el-input clearable v-model="item.tlff"></el-input>
                  </el-form-item>
                  <el-form-item label="调理处方">
                    <el-input clearable :rows="4" type="textarea" v-model="item.tlcf"></el-input>
                  </el-form-item>

                  <el-form-item :label="'调理手法['+(jsonObj.mainSymptom[index].tlsf? jsonObj.mainSymptom[index].tlsf.length:0)+']'">
                    <el-button v-if="!item.tlsf || item.tlsf.length<1" type="warning"
                               icon="el-icon-circle-plus-outline"
                               @click="onAdd(item.tlsf,-1,'')"></el-button>
                    <div style="overflow-y: auto;max-height: 250px;">
                      <el-form ref="form4" label-width="80px" :inline="true" size="mini">
                        <div v-for="(item_tlsf,index_tlsf) in item.tlsf" :key="index_tlsf"
                             style="display: flex;align-items: center;">
                          <el-form-item label="">
                            <el-input clearable v-model="item.tlsf[index_tlsf]"></el-input>
                          </el-form-item>

                          <el-button type="warning" icon="el-icon-circle-plus-outline" size="mini"
                                     @click="onAdd(item.tlsf,index_tlsf,'')"></el-button>
                          <el-button type="info" icon="el-icon-upload2" size="mini"
                                     @click="onUp(item.tlsf,index_tlsf)"></el-button>
                          <el-button type="success" icon="el-icon-download" size="mini"
                                     @click="onDown(item.tlsf,index_tlsf)"></el-button>

                          <el-button type="danger" icon="el-icon-delete" size="mini"
                                     @click="onDel(item.tlsf,index_tlsf)"></el-button>

                        </div>

                      </el-form>

                    </div>
                  </el-form-item>


                  <el-form-item :label="'辅助调理['+jsonObj.mainSymptom[index].fztl.length+']'">
                    <el-button v-if="item.fztl.length<1" type="warning"
                               icon="el-icon-circle-plus-outline"
                               @click="onAdd(item.fztl,-1,mainSymptomItemFztlItem)"></el-button>
                    <div style="overflow-y: auto;max-height: 350px;">
                      <el-form ref="form5" label-width="80px" :inline="true" size="mini">
                        <div v-for="(item_fztl,index_fztl) in item.fztl" :key="index_fztl"
                             style="display: flex;align-items: center;">
                          <el-form-item label="名称">
                            <el-input clearable v-model="item.fztl[index_fztl].name"></el-input>
                          </el-form-item>
                          <el-form-item label="说明">
                            <el-input style="width: 800px;" clearable :rows="2" type="textarea"
                                      v-model="item.fztl[index_fztl].desc"></el-input>
                          </el-form-item>

                          <el-button type="warning" icon="el-icon-circle-plus-outline" size="mini"
                                     @click="onAdd(item.fztl,index_fztl,mainSymptomItemFztlItem)"></el-button>
                          <el-button type="info" icon="el-icon-upload2" size="mini"
                                     @click="onUp(item.fztl,index_fztl)"></el-button>
                          <el-button type="success" icon="el-icon-download" size="mini"
                                     @click="onDown(item.fztl,index_fztl)"></el-button>

                          <el-button type="danger" icon="el-icon-delete" size="mini"
                                     @click="onDel(item.fztl,index_fztl)"></el-button>

                        </div>

                      </el-form>

                    </div>
                  </el-form-item>
                </div>
                <div style="border-bottom: 10px solid orange;"></div>
              </div>
            </el-form>

          </div>
          <!--expand-->
          <div v-if="selIndex=='expand'" style="">

            <el-button v-if="jsonObj.expand.length<1" type="warning" icon="el-icon-circle-plus-outline"
                       @click="onAdd(jsonObj.expand,-1,expandItem)"></el-button>

            <el-form ref="form" label-width="80px">
              <div v-for="(item,index) in jsonObj.expand" :key="index">
                <div style="margin-top:10px;">
                  <el-badge :value="index+1" class="item">
                    <el-form-item label="标题">

                      <div style="display: flex;align-items: center;">
                        <el-input clearable v-model="item.title" style="width:300px;margin-right: 25px;"></el-input>

                        <el-button type="warning" icon="el-icon-circle-plus-outline" size="mini"
                                   @click="onAdd(jsonObj.expand,index,expandItem)"></el-button>
                        <el-button type="info" icon="el-icon-upload2" size="mini"
                                   @click="onUp(jsonObj.expand,index)"></el-button>
                        <el-button type="success" icon="el-icon-download" size="mini"
                                   @click="onDown(jsonObj.expand,index)"></el-button>

                        <el-button type="danger" icon="el-icon-delete" size="mini"
                                   @click="onDel(jsonObj.expand,index)"></el-button>
                      </div>

                    </el-form-item>
                  </el-badge>
                  <el-form-item label="内容">
                    <el-input clearable :rows="4" type="textarea" v-model="item.content"></el-input>
                  </el-form-item>
                </div>
                <div style="border-bottom: 2px solid orange;"></div>
              </div>
            </el-form>

          </div>

        </div>
      </div>


    </div>
  </div>
</template>

<script>
export default {
  name: "JsonObjEdit",
  data() {
    return {
      activeIndex: 0,
      btnArr: ['编辑区', '源码区'],
      //当前编辑的JSON文本
      editJsonTxt: '',
      //当前编辑的症状ID
      editSyid: 0,
      json_name: '',
      //扩展Item 空的
      expandItem: {
        "title": "",
        "content": ""
      },
      //主症Item
      mainSymptomItem:
          {
            "name": "",
            "lcbx": [],
            "tlff": "",
            "tlcf": "",
            "tlsf": [],
            "fztl": []
          },
      //主症Item 下的 临床表现 item
      mainSymptomItemLcbxItem: {
        "title": "",
        "tag": ''
      },
      //主症Item 下的辅助调理 item
      mainSymptomItemFztlItem: {
        "name": "",
        "desc": ""
      },
      //症状JSON
      jsonObj: {
        mainSymptom: [],
        expand: [],
      },
      //当前选中菜单索引
      selIndex: 'mainSymptom',

      //症状标题
      symptomTitle: '',

      jsonTitleArr: {
        'mainSymptom': '主症',
        'expand': '扩展',
        'name': '名称',
        'lcbx': '临床表现',
        'tag': '标识',
        'tlff': '调理方法',
        'tlcf': '调理处方',
        'tlsf': "调理手法",
        'fztl': '辅助调理',
        'desc': '说明',
        'content': '备注'
      },
      jsonType:
          {
            "mainSymptom":
                [
                  {
                    "name": "",
                    "lcbx": [
                      {
                        "title": "",
                        "tag": 0
                      },
                    ],
                    "tlff": "",
                    "tlcf": "",
                    "tlsf": [],
                    "fztl": [
                      {
                        "name": "",
                        "desc": ""
                      },
                    ]
                  },
                ],
            "expand":
                [
                  {
                    "title": "",
                    "content": ""
                  }
                ]
          }
      ,

    }
  },
  created() {
    console.log('$route', this.$route)
    let query = this.$route.query || null
    //保存userid,token
    this.editSyid = query.syid || 0

    this.getJsonObj()
  },
  watch: {
    //监控JSON对象变化
    // jsonObj: {
    //   handler(newVal) {
    //     this.editJsonTxt = JSON.stringify(newVal)
    //     console.log("监控JSON对象变化", this.editJsonTxt)
    //   },
    //   deep: true,
    //   immediate: true,
    // },
    // editJsonTxt:{
    //   handler(newVal) {
    //     this.jsonObj = JSON.parse(newVal)
    //     console.log("监控JSON文本变化", this.jsonObj)
    //   },
    //   deep: true,
    //   immediate: true,
    // }
  },
  mounted() {
  },
  methods: {
    async getJsonObj() {
      if (this.editSyid < 1) {
        this.$err("没有症状ID")
        return
      }
      const res = await this.$post("SymptomList/getSymptomInfo", {
        syid: this.editSyid
      });
      console.log(res);
      if (res.code !== 200) return;

      this.jsonObj = res.data['json'] || this.jsonObj;

      this.editJsonTxt = JSON.stringify(this.jsonObj)

      this.symptomTitle = res.data['symptomTitle'];
      this.json_name = res.data['json_name'] || '';
      document.title = this.symptomTitle
    },
    //保存到服务器
    async onSaveToServer() {
      console.log("json", this.jsonObj)
      if (this.json_name.length < 1) {
        this.$err("没有文件名")
        return
      }

      const res = await this.$post("SymptomList/saveJson", {
        json_name: this.json_name,
        json: this.jsonObj
      });
      console.log(res);
      if (res.code !== 200) return;
      this.$ok("保存成功")
    },
    //按钮点击了
    onBtnClick(index) {

      if (index == 0) {
        //编辑区
        try {
          this.jsonObj = JSON.parse(this.editJsonTxt)
        } catch (e) {
          this.$err("JSON格式有误")
          return
        }

      }
      if (index == 1) {
        //源码区
        this.editJsonTxt = JSON.stringify(this.jsonObj)
      }
      this.activeIndex = index
    },
    // 检测json格式
    isJSON(str) {
      if (typeof str == 'string') {
        try {
          var obj = JSON.parse(str);
          if (typeof obj == 'object' && obj) {
            return true;
          } else {
            return false;
          }

        } catch (e) {
          return false;
        }
      } else if (typeof str == 'object' && str) {
        return true;
      }
    },
    onSaveJson() {
      if (this.activeIndex == 1) {
        if (!this.isJSON(this.editJsonTxt)) {
          this.$err(`json格式错误`)
          return false
        }
        this.jsonObj = JSON.parse(this.editJsonTxt)
      }
      //保存
      this.onSaveToServer()
    },
    //选中菜单改变
    onSelectChange(index, indexPath) {
      this.selIndex = index
    },

    //---------通用
    onUp(arr, index) {
      if (index >= 0) {
        let item = arr.splice(index, 1)
        //移到上面
        arr.splice(index - 1, 0, item[0])
        this.$ok("已上移")
      }
    },
    onDown(arr, index) {
      if (index < arr.length - 1) {
        let item = arr.splice(index, 1)
        //移到下面
        arr.splice(index + 1, 0, item[0])
        this.$ok("已下移")
      }

    },
    onAdd(arr, index, obj) {
      console.log("添加一个空的对象",arr,index,obj)
      //添加一个空的对象
      let objVal = JSON.parse(JSON.stringify(obj))
      arr.splice(index + 1, 0, objVal)
      this.$ok("已添加到下一条")
    },
    onDel(arr, index) {
      arr.splice(index, 1)
      this.$ok("已删除")
    },


  }

}
</script>

<style>
.CodeMirror {
  height: 100% !important;
}

.el-form-item--mini .el-form-item__content, .el-form-item--mini .el-form-item__label {
  line-height: 40px !important;
}

.el-form-item--mini.el-form-item, .el-form-item--small.el-form-item {
  margin-bottom: 0 !important;
}
</style>