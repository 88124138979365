import { render, staticRenderFns } from "./ChongList.vue?vue&type=template&id=03086522&scoped=true&"
import script from "./ChongList.vue?vue&type=script&lang=js&"
export * from "./ChongList.vue?vue&type=script&lang=js&"
import style0 from "./ChongList.vue?vue&type=style&index=0&id=03086522&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03086522",
  null
  
)

export default component.exports